/**
 * Copyright (C) Financial Engines, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Financial Engines
 */

(function() {
  'use strict';

  angular
    .module('legalDocPdfs', [
      'ngSanitize',
      'ui.router',
      'ui.bootstrap'
    ])
  ;

})();

(function() {
  'use strict';

  angular
    .module('legalDocPdfs')
    .factory('legalDocsService', legalDocsService)
  ;


  function legalDocsService($http) {

    var REST_BASE_URL = 'https://api.feidev.io/legal-docs/v1/legalDocuments';

    return {
      getLegalDocs: getLegalDocs
    };


    function getLegalDocs(opts) {

      var defOpts = {
        context: '',
        includeIraManagement: false
      };

      opts = angular.extend({}, defOpts, opts);

      return $http.get(REST_BASE_URL + '?', {
          params: opts

          // // Need to disable credentials. Otherwise, CORS preflight request won't pass access control check:
          // // a wildcard '*' cannot be used in the 'Access-Control-Allow-Origin' header when the credentials
          // // flag is true.
          // withCredentials: false
        }).then(function(response) {
          return response.data.docs;
        })
      ;
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('legalDocPdfs')
    .config(configHomeRoute)
  ;


  function configHomeRoute($stateProvider) {
    $stateProvider
      .state('home', {
        url: '/',
        templateUrl: 'app/home/home.html',
        controller: 'HomeController'
      })
    ;
  }

})();

(function () {
  'use strict';

  angular
    .module('legalDocPdfs')
    .controller('HomeController', HomeController)
  ;


  function HomeController($scope, $log, legalDocsService) {
    $log.log('HomeController');

    $scope.showList = false;

    $scope.options = {
      context: 'MA',
      rkId: 'JPMAC',
      poId: 'sony',
      includeIraManagement: false
    };

    $scope.getLegalDocs = function() {
      $log.log('Start to fetch legal docs');

      $scope.docs = [];
      $scope.waiting = true;
      $scope.showList = true;

      legalDocsService.getLegalDocs($scope.options).then(function(docs) {
        $log.log(docs);

        $scope.waiting = false;
        $scope.docs = docs;
      });
    };

  } // /HomeController

})();

(function() {
  'use strict';

  angular
    .module('legalDocPdfs')
    .controller('AppController', AppController)
  ;


  function AppController($log) {
    $log.log('AppController');
  }

})();

(function() {
  'use strict';

  angular
    .module('legalDocPdfs')
    .config(configApp)
  ;


  function configApp($urlRouterProvider) {
    $urlRouterProvider.otherwise('/');
  }

})();

angular.module("legalDocPdfs").run(["$templateCache", function($templateCache) {$templateCache.put("app/home/home.html","<div id=\"home\"><br><div data-fe-header=\"frame.header\"></div><div class=\"main container\"><div id=\"frm-user-inputs\" class=\"panel panel-primary\"><div class=\"panel-heading\"></div><div class=\"panel-body\"><div class=\"row\"><div class=\"col-xs-4 text-right\"><label>Context</label></div><div class=\"col-xs-8\"><input type=\"radio\" ng-model=\"options.context\" value=\"OA\">OA&nbsp;&nbsp; <input type=\"radio\" ng-model=\"options.context\" value=\"MA\">MA&nbsp;&nbsp; <input type=\"radio\" ng-model=\"options.context\" value=\"ISA\">ISA</div></div><div class=\"row\"><div class=\"col-xs-4 text-right\"><label>RK ID</label></div><div class=\"col-xs-8\"><input type=\"text\" ng-model=\"options.rkId\"></div></div><div class=\"row\"><div class=\"col-xs-4 text-right\"><label>PO ID</label></div><div class=\"col-xs-8\"><input type=\"text\" ng-model=\"options.poId\"></div></div><div class=\"row\"><div class=\"col-xs-4 text-right\"><label>Include IRA Management</label></div><div class=\"col-xs-8\"><input type=\"checkbox\" ng-model=\"options.includeIraManagement\"></div></div><div class=\"row\"><div class=\"col-xs-offset-4 col-xs-8\"><button type=\"button\" id=\"btn-get-legal-docs\" class=\"btn btn-success\" ng-click=\"getLegalDocs()\">Get legal docs</button></div></div></div></div><div id=\"lst-legal-docs\" class=\"panel panel-primary\" ng-show=\"showList\"><div class=\"panel-body\"><ul ng-show=\"docs\"><li ng-repeat=\"doc in docs\"><a ng-href=\"{{ doc.file }}\" target=\"_blank\">{{ doc.title }}</a></li></ul><div ng-show=\"waiting\" class=\"text-center wait-message\">Please wait ...</div></div></div></div></div>");
$templateCache.put("app/legal-doc-pdfs/index.html","<!DOCTYPE HTML><html><head><meta charset=\"UTF-8\"><meta http-equiv=\"refresh\" content=\"0; url=//localhost:3000/\"><title>Page Redirection</title></head><body></body></html>");}]);